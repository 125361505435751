import gql from 'graphql-tag';

export const GET_POST_LIST = gql`
  query ($page: Int, $pageSize: Int) {
    posts(page: $page, pageSize: $pageSize) {
      id
      caption
      display_caption
      bookmarked
      created_at
      tags {
        id
        name
        verified
      }
      medias {
        id
        public_url
      }
      products {
        id
        name
        slug
        medias {
          id
          public_url
        }
        price
      }
      store {
        id
        business_name
        profile_image
        currency
      }
    }
  }
`;

export const GET_POST_VENDOR_FOLLOWED = gql`
  query ($page: Int, $pageSize: Int) {
    getPostsByFollowedVendors(page: $page, pageSize: $pageSize) {
      data {
        id
        caption
        display_caption
        affiliate_link
        created_at
        bookmarked
        tags {
          id
          name
          verified
        }
        organisation {
          username
          organisation_name
          profile_image
        }
        medias {
          id
          public_url
          file_type
          images {
            explore_posts
            explore_posts_img
            explore_posts_img_placeholder
          }
        }
        products {
          id
          name
          slug
          medias {
            id
            public_url
            file_type
            images {
              vendor_products
            }
          }
          price
          is_on_sale
          sale_price
        }
        storeServices {
          id
          name
          price
          image_url
        }
        store {
          id
          business_name
          profile_image
          username
          currency
        }
      }
      total
      page
      pageSize
      offset
    }
  }
`;

export const GET_POSTS_BY_USER_INTEREST = gql`
  query ($interestIds: [ID], $page: Int, $pageSize: Int) {
    getPostsByUserInterests(
      interestIds: $interestIds
      page: $page
      pageSize: $pageSize
    ) {
      data {
        id
        caption
        display_caption
        affiliate_link
        bookmarked
        created_at
        organisation {
          organisation_name
          username
          profile_image
        }
        tags {
          id
          name
          verified
        }
        medias {
          id
          public_url
          file_type
          images {
            explore_posts
            explore_posts_img
            explore_posts_img_placeholder
          }
        }
        products {
          id
          name
          slug
          medias {
            id
            public_url
            file_type
            images {
              vendor_products
            }
          }
          price
          is_on_sale
          sale_price
        }
        store {
          id
          business_name
          profile_image
          username
          currency
        }
        stores {
          id
          business_name
          profile_image
          username
          currency
        }
      }
      total
      page
      pageSize
      offset
    }
  }
`;

export const GET_EXPLORE_LIST = gql`
  query ($page: Int, $pageSize: Int) {
    posts(page: $page, pageSize: $pageSize) {
      data {
        id
        caption
        display_caption
        affiliate_link
        bookmarked
        created_at
        organisation {
          organisation_name
          username
          profile_image
        }
        tags {
          id
          name
          verified
        }
        medias {
          id
          public_url
          file_type
          images {
            explore_posts
            explore_posts_img
            explore_posts_img_placeholder
          }
        }
        products {
          id
          name
          slug
          medias {
            id
            public_url
            file_type
            images {
              vendor_products
            }
          }
          price
          is_on_sale
          sale_price
        }
        store {
          id
          business_name
          profile_image
          username
          currency
        }
        stores {
          id
          business_name
          profile_image
          username
          currency
        }
      }
      total
      page
      pageSize
      offset
    }
  }
`;

export const GET_POST_BY_ID = gql`
  query ($id: ID!) {
    getPost(id: $id) {
      id
      caption
      display_caption
      affiliate_link
      bookmarked
      created_at
      tags {
        id
        name
        verified
        tag_type
      }
      organisation {
        organisation_name
        username
        profile_image
      }
      medias {
        id
        public_url
        public_image_url
        file_type
        images {
          explore_posts
          explore_posts_img
          explore_posts_img_placeholder
        }
        thumbnails {
          dropzone_post
        }
      }
      products {
        id
        name
        slug
        is_on_sale
        sale_price
        medias {
          id
          public_url
          images {
            vendor_products
          }
        }
        price
      }
      storeServices {
        id
        name
      }
      store {
        id
        business_name
        profile_image
        username
      }
    }
  }
`;

export const GET_POSTS_BY_SEARCH = gql`
  query ($search: String!, $page: Int, $pageSize: Int) {
    searchPosts(search: $search, page: $page, pageSize: $pageSize) {
      data {
        id
        caption
        display_caption
        created_at
        medias {
          id
          public_url
          file_type
          images {
            explore_posts
            explore_posts_img_placeholder
          }
          thumbnails {
            search_posts
          }
        }
      }
      total
      page
      pageSize
      offset
    }
  }
`;

export const GET_BOOKMARKED_POSTS = gql`
  query getBookmarkedPosts {
    getBookmarkedPosts {
      id
      caption
      display_caption
      created_at
      medias {
        id
        public_url
        file_type
        images {
          explore_posts
          explore_posts_img_placeholder
        }
        thumbnails {
          search_posts
        }
      }
    }
  }
`;
