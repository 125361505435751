import Post from './Post';
import { GET_POSTS_BY_USER_INTEREST } from '../../../frontend/graphQL/queries/post';
import { useApolloClient } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  scroll: {
    overflow: 'inherit !important',
  },
}));

const Explore = ({ isLoggedIn, isVendor }) => {
  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const [postList, setPostList] = useState({ total: '', page: '' });
  const client = useApolloClient();
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let interestIds = [];

    if (localStorage.getItem('interestIds')) {
      interestIds = JSON.parse(localStorage.getItem('interestIds'));
    }
    client
      .query({
        query: GET_POSTS_BY_USER_INTEREST,
        variables: {
          interestIds,
          page: 1,
          pageSize: 3,
        },
      })
      .then((res) => {
        setLoading(false);
        setPosts(res?.data?.getPostsByUserInterests?.data);
        setPostList({
          total: res?.data?.getPostsByUserInterests?.total,
          page: res?.data?.getPostsByUserInterests?.page,
        });
        if (
          res?.data?.getPostsByUserInterests?.data.length >=
          Number(res?.data?.getPostsByUserInterests?.total)
        ) {
          setHasMore(false);
          setLoading(false);
        }
      });
  }, []);

  const fetchData = () => {
    if (posts.length >= Number(postList.total)) {
      setHasMore(false);
      setLoading(false);
    }

    let interestIds = [];

    if (localStorage.getItem('interestIds')) {
      interestIds = JSON.parse(localStorage.getItem('interestIds'));
    }

    client
      .query({
        query: GET_POSTS_BY_USER_INTEREST,
        variables: {
          page: Number(postList?.page) + 1,
          interestIds,
          pageSize: 3,
        },
      })
      .then((res) => {
        setLoading(false);
        setPosts([...posts, ...res?.data?.getPostsByUserInterests?.data]);
        setPostList({
          total: res?.data?.getPostsByUserInterests?.total,
          page: res?.data?.getPostsByUserInterests?.page,
        });
      });
  };

  if (loading) {
    return (
      <Grid
        style={{
          textAlign: 'center',
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 99999,
        }}
      >
        <CircularProgress size="3rem" />
      </Grid>
    );
  }
  return (
    <div>
      {posts && posts.length == 0 ? (
        <Grid style={{ textAlign: 'center', marginTop: '50%' }}>
          <Typography>No post available!!!</Typography>
        </Grid>
      ) : (
        <InfiniteScroll
          //pullDownToRefresh={true}
          // refreshFunction={() => {
          //   route.reload();
          // }}
          // pullDownToRefreshThreshold={100}
          // pullDownToRefreshContent={<div style={{ height: 100 }} />}
          dataLength={posts?.length}
          next={fetchData}
          hasMore={hasMore}
          className={classes.scroll}
          loader={
            <Grid style={{ textAlign: 'center' }}>
              <CircularProgress size="2rem" />
            </Grid>
          }
        >
          {posts &&
            posts.map((post, index) => (
              <Post
                key={index}
                post={post}
                showSetting={false}
                isLoggedIn={isLoggedIn}
                isVendor={isVendor}
              />
            ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

Explore.propTypes = {
  isLoggedIn: PropTypes.bool,
  isVendor: PropTypes.bool,
};
export default Explore;
